import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";

const TermsAndConditions = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container maxWidth="md" sx={{ my: 5 }}>
			<Typography variant="h4" gutterBottom>
				Terms and Conditions
			</Typography>
			<Typography variant="subtitle1" color="text.secondary" gutterBottom>
				Effective Date: 13th January 2025
			</Typography>

			<Typography variant="body1" paragraph>
				Spyware Computer Education welcomes you to our institute and appreciates your interest in our courses. By enrolling in our programs, you agree to abide by the following terms and conditions.
			</Typography>

			{/* Section 1 */}
			<Typography variant="h6" gutterBottom>
				1. Enrollment and Admission
			</Typography>
			<Typography variant="body1" paragraph>
				1.1 Students must provide accurate and complete information during the admission process.
			</Typography>
			<Typography variant="body1" paragraph>
				1.2 Admission is subject to verification of submitted documents and payment of applicable fees.
			</Typography>
			<Typography variant="body1" paragraph>
				1.3 Spyware Computer Education reserves the right to accept or reject applications based on eligibility criteria.
			</Typography>

			{/* Section 2 */}
			<Typography variant="h6" gutterBottom>
				2. Fee Structure and Payment Policy
			</Typography>
			<Typography variant="body1" paragraph>
				2.1 All fees must be paid as per the schedule provided at the time of admission.
			</Typography>
			<Typography variant="body1" paragraph>
				2.2 Fees once paid are non-refundable, except in cases of course cancellation by the institute.
			</Typography>
			<Typography variant="body1" paragraph>
				2.3 Late payments may incur additional charges.
			</Typography>

			{/* Section 3 */}
			<Typography variant="h6" gutterBottom>
				3. Attendance and Punctuality
			</Typography>
			<Typography variant="body1" paragraph>
				3.1 Students are expected to maintain at least 70% attendance for successful course completion.
			</Typography>
			<Typography variant="body1" paragraph>
				3.2 Frequent tardiness or absenteeism may result in disciplinary actions, including removal from the course.
			</Typography>

			{/* Section 4 */}
			<Typography variant="h6" gutterBottom>
				4. Code of Conduct
			</Typography>
			<Typography variant="body1" paragraph>
				4.1 Students must maintain decorum, respect faculty and peers, and adhere to institute policies.
			</Typography>
			<Typography variant="body1" paragraph>
				4.2 Any form of misconduct, harassment, or inappropriate behavior may result in suspension or expulsion.
			</Typography>
			<Typography variant="body1" paragraph>
				4.3 Use of electronic devices during class is permitted only with the instructor’s consent.
			</Typography>

			{/* Additional Sections */}
			{/* Add other sections (5–11) similarly using Typography */}

			{/* Final Notes */}
			<Box mt={4}>
				<Typography variant="body1" paragraph>
					By enrolling at Spyware Computer Education, you acknowledge that you have read, understood, and agreed to these terms and conditions.
				</Typography>
				<Typography variant="body1">For any queries or concerns, please contact us at:</Typography>
				<Typography variant="body2" mt={2}>
					Spyware Computer Education
					<br />
					Mirchaibari Road, Katihar - 854105, Bihar
					<br />
					connectwith@spywarecomputers.com
					<br />
					+91 76314 73503
				</Typography>
			</Box>
		</Container>
	);
};

export default TermsAndConditions;
