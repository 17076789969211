import { AttachFile, CalendarMonth, Female, Image, Male, Visibility } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Profile({ snackbar }) {
	const apiUrl = process.env.REACT_APP_API;
	const [action, setAction] = useState("");
	const [open, setOpen] = useState("");

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	const [profile, setProfile] = useState([]);
	useEffect(() => {
		get_profile();
		get_documents();
	}, []);

	const get_profile = async () => {
		try {
			const response = await axios.post(`${apiUrl}/student/v2?action=profile`);
			if (response.data.status === true) {
				setProfile(response.data.data);
			} else {
				setProfile(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
		}
	};

	const [document, setDocument] = useState([]);
	const get_documents = async () => {
		try {
			const response = await axios.post(`${apiUrl}/student/v2?action=documents`);
			if (response.data.status === true) {
				setDocument(response.data.data);
			} else {
				setDocument(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
		}
	};

	const [docType, setDocType] = useState("");
	const upload_document = async (img) => {
		try {
			setAction("uploadingdocument");
			let formData = new FormData();
			formData.append("documentType", docType);
			formData.append("img", img.target.files[0]);
			const response = await axios.post(`${apiUrl}/student/v2?action=upload-document`, formData);
			snackbar(response.data.msg);
			if (response.data.status === true) {
				get_documents();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAction("");
		}
	};

	const [docImg, setDocImg] = useState("");

	return (
		<Box className="content">
			<Dialog open={open === "opendocument" ? true : false} onClose={() => setOpen("")}>
				<DialogContent>
					<img src={`../../spywareassets/student/document/${docImg}`} />
				</DialogContent>
			</Dialog>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Card sx={{ height: "100%" }}>
							<CardContent>
								<Avatar src={`../../spywareassets/student/${profile.studentid}.jpg`} sx={{ width: 120, height: 120, mx: "auto" }} />
								<Box textAlign={"center"}>
									<Typography mt={1} variant="h5" fontWeight={"bold"}>
										{profile.name}
									</Typography>
									<Stack direction={"row"} justifyContent={"center"} mt={1} spacing={0.5}>
										<Chip variant="outlined" icon={<CalendarMonth />} size="small" label={new Date(profile.birth).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric" })} color="success" />
										<Chip label={profile.gender} icon={profile.gender === "Male" ? <Male /> : <Female />} variant="outlined" size="small" color="info" />
									</Stack>
								</Box>
								<Box mt={3}>
									<Box mb={1}>
										<Typography fontSize={12} color={"gray"}>
											Email
										</Typography>
										<Typography fontSize={14} fontWeight={500}>
											{profile.email}
										</Typography>
									</Box>
									<Divider />
									<Box mt={1} mb={1}>
										<Typography fontSize={12} color={"gray"}>
											Mobile
										</Typography>
										<Typography fontSize={14} fontWeight={500}>
											{profile.mobile}
										</Typography>
									</Box>
									<Divider />
									<Box mt={1}>
										<Typography fontSize={12} color={"gray"}>
											Address
										</Typography>
										<Typography fontSize={14} fontWeight={500}>
											{profile.address}
										</Typography>
									</Box>
								</Box>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Box mt={1} mb={1}>
									<Typography fontSize={12} color={"gray"}>
										Aadhaar Number
									</Typography>
									<Typography fontSize={14} fontWeight={500}>
										{profile.aadhaar}
									</Typography>
								</Box>
								<Divider sx={{ fontSize: 12, color: "gray" }}>Guardian Details</Divider>
								<Box mt={1}>
									<Typography fontSize={12} color={"gray"}>
										Father Name
									</Typography>
									<Typography fontSize={14} fontWeight={500}>
										{profile.guardian}
									</Typography>
								</Box>
								<Box mt={1}>
									<Typography fontSize={12} color={"gray"}>
										Mother Name
									</Typography>
									<Typography fontSize={14} fontWeight={500}>
										{profile.mother}
									</Typography>
								</Box>
							</CardContent>
						</Card>
						<Card sx={{ mt: 2 }}>
							<CardContent>
								<Stack direction={"column"} spacing={1}>
									<FormControl fullWidth size="small">
										<InputLabel id="demo-simple-select-label">Document Type</InputLabel>
										<Select onChange={(event) => setDocType(event.target.value)} value={docType} labelId="demo-simple-select-label" id="demo-simple-select" label="Document Type">
											<MenuItem value={"aadhaar card"}>Aadhaar Card</MenuItem>
											<MenuItem value={"marksheet"}>Marksheet</MenuItem>
											<MenuItem value={"others"}>Others</MenuItem>
										</Select>
									</FormControl>
									<Button fullWidth component="label" disabled={action === "uploadingdocument" ? true : false} role={undefined} variant="outlined" color="info" startIcon={action === "uploadingdocument" ? <CircularProgress color="inherit" size={20} /> : <Image />}>
										Document Image
										<VisuallyHiddenInput onChange={(event) => upload_document(event)} type="file" accept=".jpeg, .jpg" />
									</Button>
								</Stack>

								{document.status === false ? (
									<Alert sx={{ mt: 2 }} severity="info">
										{document.msg}
									</Alert>
								) : (
									<Box mt={1}>
										<Divider sx={{ fontSize: 12, color: "gray" }}>Uploaded Documents</Divider>
										{document.map((item, index) => {
											return (
												<Stack key={index} mt={2} direction={"row"} spacing={1} mb={2}>
													<Chip sx={{ borderRadius: 2, textTransform: "capitalize" }} color="info" variant="outlined" icon={<AttachFile />} label={item.document_type} />
													<Chip
														onClick={() => {
															setDocImg(item.document_file);
															setOpen("opendocument");
														}}
														sx={{ borderRadius: 2 }}
														clickable
														color="success"
														variant="outlined"
														icon={<Visibility />}
														label={"View"}
													/>
												</Stack>
											);
										})}
									</Box>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
