import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import MyContext from "../../context/context";

// MUI
import { Call, Facebook, HomeSharp, Instagram, WhatsApp, YouTube } from "@mui/icons-material";
import { AppBar, Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Stack, TextField, Toolbar, Typography } from "@mui/material";

export default function StudentLogin({ snackbar }) {
	const api = useContext(MyContext);
	const navi = useNavigate();
	const auth = Cookies.get("authuser");
	const [action, setAction] = useState("");

	useEffect(() => {
		if (auth === "student") {
			navi("/student/home", { replace: true });
		}
	}, [auth]);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const login = () => {
		setAction("working");
		var formData = new FormData();
		formData.append("login-student", email);
		formData.append("password", password);
		fetch(api.api.login, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				setAction("");
				snackbar(data.message);
				if (data.status === true) {
					navi("/student/home", { replace: true });
				}
			});
	};

	return (
		<Box>
			<AppBar color="info" position="sticky" elevation={1}>
				<Toolbar>
					<img src="logo_text_white.png" style={{ width: 200 }} />
					<Typography flexGrow={1} />
					<IconButton edge="end" color="inherit" onClick={() => navi("/")}>
						<HomeSharp />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ position: "fixed", width: "100%", padding: 0.5, bottom: 0 }}>
				<Divider>
					<Typography textAlign={"center"} fontSize={14} color={"warning.main"}>
						Follow us on
					</Typography>
				</Divider>
				<Box textAlign={"center"}>
					<IconButton color="info" size="large" onClick={() => (window.location.href = "https://www.instagram.com/spywarecomputers/")}>
						<Instagram />
					</IconButton>
					<IconButton color="info" size="large" onClick={() => (window.location.href = "https://www.facebook.com/profile.php?id=61556216739412")}>
						<Facebook />
					</IconButton>
					<IconButton color="info" size="large" onClick={() => (window.location.href = "https://www.youtube.com/channel/UC23IqIFehYq6DTY1_857aOA")}>
						<YouTube />
					</IconButton>
					<IconButton color="info" size="large" onClick={() => (window.location.href = "https://wa.me/917631473503")}>
						<WhatsApp />
					</IconButton>
					<IconButton color="info" size="large" onClick={() => (window.location.href = "tel: 917631473503")}>
						<Call />
					</IconButton>
				</Box>
			</Box>
			<Box>
				<Container>
					<Grid container mt={7}>
						<Grid item xs={12} md={6} mx={"auto"}>
							<Box mb={2} sx={{ padding: 2 }} component="form">
								<Typography fontSize={20} fontWeight={600}>
									Login as student
								</Typography>
								<Stack direction={"column"} spacing={1.5}>
									<TextField fullWidth variant="standard" type="email" label="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
									<TextField fullWidth variant="standard" type="password" label="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
								</Stack>
								<Box textAlign={"center"} mb={1} mt={2}>
									<Button variant="contained" color="info" sx={{ width: "100%" }} type="submit" onClick={() => login()} disabled={action === "working" ? true : false}>
										{action === "working" ? <CircularProgress color="inherit" size={24} /> : "LOGIN NOW"}
									</Button>
								</Box>
								<NavLink to={"/terms&conditions"} style={{ color: "gray", fontSize: 12 }}>
									*By continuing to use our website or app, you are agreeing to our terms and conditions.
								</NavLink>
								<Stack direction={"row"} mt={2} justifyContent={"space-between"}>
									<Button variant="outlined" color="error" size="small" onClick={() => navi("/reset-student")}>
										Reset password
									</Button>
									<Button variant="outlined" color="success" size="small" onClick={() => navi("/admission")}>
										Join spyware
									</Button>
								</Stack>
							</Box>

							<Divider>
								<Typography>FRANCHISE</Typography>
							</Divider>

							<Box sx={{ padding: 2, mt: 2 }}>
								<Typography fontSize={20} fontWeight={600}>
									Are you a franchise?
								</Typography>
								<Box textAlign={"center"} mt={2}>
									<Button variant="outlined" color="info" sx={{ width: "100%" }} onClick={() => navi("/login-franchise")}>
										LOGIN HERE
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	);
}
