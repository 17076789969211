import { ThemeProvider } from "@emotion/react";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import MyState from "./context/state";

// MUI
import { Info } from "@mui/icons-material";
import { Alert, Snackbar, createTheme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// PAGES
import Contact from "./pages/contact";
import CoursesList from "./pages/courses";
import GalleryPublic from "./pages/gallery";
import Header from "./pages/header";
import Home from "./pages/home";
import InstallApp from "./pages/light-app";
import SharePublic from "./pages/share";
import Verification from "./pages/verification";
import VerifiedCertificate from "./pages/verify";
import Workshop from "./public/workshop-form";

// Controller
import Batch from "./controller/batch";
import Courses from "./controller/course";
import CourseContent from "./controller/course-content";
import CourseSubject from "./controller/course-subject";
import CourseUpload from "./controller/course-upload";
import DashboardController from "./controller/dashboard";
import DashboardFranchise from "./controller/dashboard-franchise";
import Franchises from "./controller/franchise";
import FranchiseUpload from "./controller/franchise-upload";
import Gallery from "./controller/gallery";
import HeroSliderAdmin from "./controller/hero-slider";
import MessageAdmin from "./controller/message";
import NotificationAdmin from "./controller/notification";
import PaymentReport from "./controller/payment/report";
import ReviewAdmin from "./controller/review";
import SidebarController from "./controller/sidebar";
import Students from "./controller/student";
import StudentInfo from "./controller/student-info";
import StudentMarks from "./controller/student-marks";
import StudentUpload from "./controller/student-upload";
import TeacherAdmin from "./controller/teacher";
import WorkshopData from "./controller/workshop";
import FranchiseReset from "./login//franchise/reset";
import AdminLogin from "./login/admin/index";
import AdminReset from "./login/admin/reset";
import FranchiseLogin from "./login/franchise/index";

// User
import SidebarUser from "./student/sidebar";

// Payment
import Payment from "./controller/payment";

// Attendance
import AttendanceFranchise from "./controller/attendance";
import IDCard from "./controller/attendance/idcard";
import Scanner from "./controller/attendance/scanner";

// PUBLIC
import PaymentReceipt from "./pages/payment-receipt";
import ComputerCertificate from "./public/certificate";
import ComputerMarksheet from "./public/marksheet";
import TermsAndConditions from "./public/terms&conditions";
import TypingCertificate from "./public/typing-certificate";

// Student
import StudentLogin from "./login/student";
import StudentReset from "./login/student/reset";
import AdmissionStudent from "./pages/admission";
import StudentHome from "./student";
import AttendanceStudent from "./student/attendance";
import PaymentStudent from "./student/payment";
import Profile from "./student/profile";

function App() {
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1300,
				xl: 1920,
			},
		},
		palette: {
			primary: {
				light: "#000000",
				main: "#000000",
				dark: "#000000",
				contrastText: "#0BDA51",
			},
			secondary: {
				main: "#0BDA51",
			},
			error: {
				main: "#FF0800",
			},
			info: {
				main: "#007FFF",
			},
		},
		typography: {
			button: {
				textTransform: "initial",
			},
		},

		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						boxShadow: "none",
					},
				},
			},
		},
	});

	const fullscreen = useMediaQuery(useTheme().breakpoints.down("md"));

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const handlesnackbar = (data) => {
		setMessage(data);
		setOpen(true);
	};

	const auth = Cookies.get("authuser");

	// APIS
	const apilogin = "/spywareassets/api/login/index.php";
	const apiadmin = "/spywareassets/api/admin/index.php";
	const apiglobal = "/spywareassets/api/global/index.php";
	const apipublic = "/spywareassets/api/public/index.php";

	const stuimg = "/spywareassets/student/";
	const gallery = "/spywareassets/gallery/";

	return (
		<MyState>
			<ThemeProvider theme={theme}>
				<Snackbar open={open} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
					<Alert icon={<Info />} severity="success" variant="filled" onClose={() => setOpen(false)}>
						{message}
					</Alert>
				</Snackbar>
				<Routes>
					<Route path="/" element={<Header />}>
						<Route path="/" element={<Home snackbar={handlesnackbar} />} />
						<Route path="/verification" element={<Verification apipublic={apipublic} snackbar={handlesnackbar} />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/courses" element={<CoursesList />} />
						<Route path="/verify" element={<VerifiedCertificate apipublic={apipublic} snackbar={handlesnackbar} stuimg={stuimg} />} />
						<Route path="/gallery" element={<GalleryPublic apipublic={apipublic} gallery={gallery} />} />
						<Route path="/share" element={<SharePublic />} />
						<Route path="/workshop" element={<Workshop snackbar={handlesnackbar} />} />
						<Route path="/terms&conditions" element={<TermsAndConditions />} />

						{/* Student */}
						<Route path="admission" element={<AdmissionStudent snackbar={handlesnackbar} />} />
					</Route>

					<Route path="/login-admin" element={<AdminLogin apiadminlogin={apilogin} snackbar={handlesnackbar} />} />
					<Route path="/reset-admin" element={<AdminReset apilogin={apilogin} snackbar={handlesnackbar} />} />
					<Route path="/login-student" element={<StudentLogin snackbar={handlesnackbar} />} />

					<Route path="/login-franchise" element={<FranchiseLogin apilogin={apilogin} snackbar={handlesnackbar} />} />
					<Route path="/reset-franchise" element={<FranchiseReset apilogin={apilogin} snackbar={handlesnackbar} />} />
					<Route path="/reset-student" element={<StudentReset snackbar={handlesnackbar} />} />
					<Route path="/light-app" element={<InstallApp />} />

					<Route path="/public/computer-certificate/:studentid/:courseid" element={<ComputerCertificate apipublic={apipublic} />} />
					<Route path="/public/computer-marksheet/:studentid/:courseid" element={<ComputerMarksheet apipublic={apipublic} />} />
					<Route path="/public/typing-certificate/:studentid/:courseid" element={<TypingCertificate apipublic={apipublic} />} />
					<Route path="/payment-receipt" element={<PaymentReceipt />} />

					<Route path="/controller" element={<SidebarController apiglobal={apiglobal} />}>
						<Route path="dashboard" element={<DashboardController apiadmin={apiadmin} snackbar={handlesnackbar} />} />
						<Route path="dashboard-franchise" element={<DashboardFranchise apiglobal={apiglobal} />} />
						<Route path="franchises/*" element={<Franchises snackbar={handlesnackbar} apiadmin={apiadmin} />} />
						<Route path="franchise-upload/:franchiseid" element={<FranchiseUpload snackbar={handlesnackbar} apiadmin={apiadmin} />} />
						<Route path="courses" element={<Courses apiadmin={apiadmin} />} />
						<Route path="course-upload/:courseid" element={<CourseUpload apiadmin={apiadmin} snackbar={handlesnackbar} />} />
						<Route path="course-subject/:courseid" element={<CourseSubject apiadmin={apiadmin} snackbar={handlesnackbar} />} />
						<Route path="students" element={<Students auth={auth} apiglobal={apiglobal} />} />
						<Route path="student-upload/:studentid" element={<StudentUpload auth={auth} apiglobal={apiglobal} apiadmin={apiadmin} snackbar={handlesnackbar} />} />
						<Route path="student-info/:studentid" element={<StudentInfo apiadmin={apiadmin} apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} fullscreen={fullscreen} />} />
						<Route path="student-marks/:studentid/:courseid" element={<StudentMarks apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} />} />
						<Route path="gallery" element={<Gallery apiadmin={apiadmin} snackbar={handlesnackbar} gallery={gallery} />} />
						<Route path="batch" element={<Batch />} />
						<Route path="workshop" element={<WorkshopData snackbar={handlesnackbar} />} />
						<Route path="payment-report" element={<PaymentReport />} />
						<Route path="website-course" element={<CourseContent snackbar={handlesnackbar} />} />
						<Route path="teacher" element={<TeacherAdmin snackbar={handlesnackbar} />} />
						<Route path="review" element={<ReviewAdmin snackbar={handlesnackbar} />} />
						<Route path="message" element={<MessageAdmin snackbar={handlesnackbar} />} />
						<Route path="notification" element={<NotificationAdmin snackbar={handlesnackbar} />} />
						<Route path="hero-slider" element={<HeroSliderAdmin snackbar={handlesnackbar} />} />

						{/* Attendance */}
						<Route path="attendance" element={<AttendanceFranchise apiglobal={apiglobal} />} />

						{/* Payment */}
						<Route path="payment" element={<Payment snackbar={handlesnackbar} />} />
					</Route>

					{/* Student */}
					<Route path="/student" element={<SidebarUser />}>
						<Route path="home" element={<StudentHome snackbar={handlesnackbar} />} />
						<Route path="payment" element={<PaymentStudent />} />
						<Route path="attendance" element={<AttendanceStudent />} />
						<Route path="profile" element={<Profile snackbar={handlesnackbar} />} />
					</Route>

					{/* Attendance */}
					<Route path="/attendance/scanner" element={<Scanner />} />
					<Route path="/idcard/:studentid/:courseid" element={<IDCard />} />
				</Routes>
			</ThemeProvider>
		</MyState>
	);
}

export default App;
