import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import {} from "react-router-dom";

import MyContext from "./../context/context";

// MUI
import styled from "@emotion/styled";
import { AccessTime, CalendarMonth, CameraAlt, CurrencyRupee, QrCode2, School, Timelapse } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, IconButton, Rating, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";

export default function StudentHome({ snackbar }) {
	const api = useContext(MyContext);
	const [open, setOpen] = useState("");
	const [action, setAction] = useState("");
	const apiUrl = process.env.REACT_APP_STUDENT_V2;

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	useEffect(() => {
		profile();
	}, []);
	const [stu, setStu] = useState([]);
	const profile = () => {
		axios.get(`${api.api.student}?my-profile`).then(function (res) {
			if (res.data.status === true) {
				setStu(res.data.data);
			}
		});
	};

	useEffect(() => {
		dashboard();
	}, []);
	const [att, setAtt] = useState([]);
	const [pay, setPay] = useState([]);
	const [course, setCourse] = useState([]);
	const dashboard = () => {
		axios.get(`${api.api.student}?my-dashboard`).then(function (res) {
			if (res.data.status !== false) {
				if (res.data.att.status === true) {
					setAtt(res.data.att.data);
				}
				if (res.data.pay.status === true) {
					setPay(res.data.pay.data);
				}
				if (res.data.course.status === true) {
					setCourse(res.data.course.data);
				}
			}
		});
	};

	const change_photo = (event) => {
		snackbar("Uploading...");
		var formData = new FormData();
		formData.append("add-photo", stu.studentid);
		formData.append("image", event.target.files[0]);
		axios.post(api.api.student, formData).then(function (res) {
			if (res.data.status === true) {
				window.location.reload();
			}
		});
	};

	useEffect(() => {
		axios.get(`${apiUrl}?action=my-review`).then(function (res) {
			if (res.data.status === false) {
				setOpen("review");
			}
		});
	}, []);

	const [rating, setRating] = useState(0);
	const [review, setReview] = useState("");

	const post_review = () => {
		setAction("review");
		let formData = new FormData();
		formData.append("rating", rating);
		formData.append("review", review);
		axios.post(`${apiUrl}?action=post-review`, formData).then(function (res) {
			setAction("");
			setOpen("");
			snackbar(res.data.msg);
		});
	};

	return (
		<Box className="content">
			<Dialog open={open === "qropen" ? true : false} onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<QRCode size={270} value={stu.studentid === undefined ? "" : stu.studentid} viewBox={`0 0 256 256`} />
					</Box>
				</DialogContent>
			</Dialog>
			<Dialog open={open === "review" ? true : false} onClose={() => setOpen("")} fullWidth maxWidth="xs">
				<DialogContent>
					<Box textAlign={"center"}>
						<Typography mt={1} color={`info.main`} fontSize={23} fontWeight="bold">
							Your feedback is very important for us.
						</Typography>
						<Typography mt={1} mb={2}>
							Please rate us.
						</Typography>

						<Stack spacing={2} alignItems={"center"}>
							<Rating name="half-rating" size="large" value={rating} onChange={(event) => setRating(event.target.value)} />

							<TextField fullWidth label="Type your comment here" multiline rows={2} value={review} onChange={(event) => setReview(event.target.value)} />
						</Stack>

						<Box mt={2}>
							<Button variant="contained" size="large" fullWidth color="info" onClick={() => post_review()} disabled={action === "review" ? true : false}>
								{action === "review" ? <CircularProgress size={20} /> : "Post Review"}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
			<Container>
				<Card>
					<CardContent>
						<Box textAlign={"center"}>
							<Badge
								overlap="circular"
								anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								badgeContent={
									<IconButton component="label">
										<CameraAlt />
										<VisuallyHiddenInput onChange={(event) => change_photo(event)} type="file" accept=".jpeg, .jpg, .png" />
									</IconButton>
								}
							>
								<Avatar alt={stu.name} sx={{ width: 120, height: 120 }} src={`${api.api.stuimg + stu.studentid}.jpg`} />
							</Badge>
							<Typography mt={1} fontSize={25} fontWeight="bold" className="orbitron" color={`info.main`}>
								{stu.name}
							</Typography>
							<Typography fontSize={14} color={"gray"}>
								Roll No. {stu.rollno}
							</Typography>
						</Box>
					</CardContent>
				</Card>
				<Box mt={2}>
					<Button variant="contained" startIcon={<QrCode2 />} color="info" sx={{ width: "100%" }} size="large" onClick={() => setOpen("qropen")}>
						Make Attendance
					</Button>
				</Box>
				<Grid container mt={1} spacing={2}>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Typography mb={2} fontSize={20} fontWeight={600} color={`info.main`}>
									Attendance
								</Typography>
								<Stack mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										Attend days
									</Typography>
									<Chip icon={<CalendarMonth />} label={`${att.attend} Days came`} variant="outlined" color="error" />
								</Stack>
								<Divider />
								<Stack mt={1} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										{new Date().toLocaleDateString(undefined, { weekday: "long", day: "2-digit" })} In
									</Typography>
									<Chip icon={<AccessTime />} label={`${att.at_in !== null ? new Date(att.at_in).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit", hour12: true }) : "Waiting"} `} color="info" />
								</Stack>
								<Divider />
								<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										{new Date().toLocaleDateString(undefined, { weekday: "long", day: "2-digit" })} Out
									</Typography>
									<Chip icon={<AccessTime />} label={`${att.at_out !== null ? new Date(att.at_out).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit", hour12: true }) : "Waiting"} `} color="warning" />
								</Stack>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Typography mb={2} fontSize={20} fontWeight={600} color={`info.main`}>
									Payment
								</Typography>
								<Stack mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										Fees{parseInt(pay.fees) > 0 && "/Discount"}
									</Typography>
									<Stack direction={"row"} spacing={0.5}>
										<Chip icon={<CurrencyRupee />} label={`${pay.fees > 0 && parseInt(pay.fees).toLocaleString("en-in")}`} variant="outlined" color="error" />
										{parseInt(pay.fees) > 0 && <Chip icon={<CurrencyRupee />} label={parseInt(pay.discount).toLocaleString("en-in")} variant="outlined" color="error" />}
									</Stack>
								</Stack>
								<Divider />
								<Stack mt={1} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										Payable amount
									</Typography>
									<Chip label={parseInt(parseInt(pay.fees) - parseInt(pay.discount)).toLocaleString("en-in")} color="success" icon={<CurrencyRupee />} />
								</Stack>
								<Divider />
								<Stack mt={1} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										Payed amount
									</Typography>
									<Chip label={parseInt(pay.payed).toLocaleString("en-in")} color="info" icon={<CurrencyRupee />} />
								</Stack>
								<Divider />
								<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Typography fontSize={14} color={"gray"}>
										Left payment
									</Typography>
									<Chip label={parseInt(parseInt(pay.fees) - parseInt(pay.discount) - parseInt(pay.payed)).toLocaleString("en-in")} color="warning" icon={<CurrencyRupee />} />
								</Stack>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Typography mb={2} fontSize={20} fontWeight={600} color={`info.main`}>
									Joined Course
								</Typography>
								{course.map((row, i) => (
									<Box key={i} mt={2} sx={{ border: "thin solid #E5E4E2" }} p={2} borderRadius={2}>
										<Typography mb={1} fontSize={16} color={"warning.main"} fontWeight={500}>
											{row.c_name}
										</Typography>
										<Chip size="small" label={row.c_title} icon={<School />} variant="outlined" color="warning" />
										<Chip size="small" label={`${row.c_duration} Months`} icon={<Timelapse />} variant="outlined" color="info" sx={{ ml: 0.5 }} />
									</Box>
								))}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
