import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../bar";

// MUI
import { Add, AttachFile, CalendarMonth, CameraAlt, CircleNotifications, Delete, ExpandMore, Image, Info, Visibility } from "@mui/icons-material";
import { Alert, Avatar, Backdrop, Badge, Box, Button, Card, CardContent, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";

export default function StudentInfo({ apiadmin, apiglobal, snackbar, auth, fullscreen }) {
	const apiUrl = process.env.REACT_APP_API;
	const { studentid } = useParams();
	let navi = useNavigate();
	const [action, setAction] = useState("");
	const [opendialog, setOpendialog] = useState("");

	useEffect(() => {
		studentDetails();
		assignedCourses();
		getCourses();
	}, []);
	const [student, setStudent] = useState([]);
	const studentDetails = () => {
		var formData = new FormData();
		formData.append("get-student-detail", studentid);
		fetch(apiglobal, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				data.status === false ? snackbar(data.message) : setStudent(data.data);
			});
	};

	const [acourse, setAcourse] = useState([]);
	const assignedCourses = () => {
		var formData = new FormData();
		formData.append("assigned-courses", studentid);
		fetch(apiglobal, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				data.status === false ? setAcourse(data) : setAcourse(data.data);
			});
	};

	const [courses, setCourses] = useState([]);
	const [opencoursedialog, setOpencoursedialog] = useState(false);
	const getCourses = () => {
		var formData = new FormData();
		formData.append("get-courses", "");
		fetch(apiglobal, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				data.status === false ? setCourses(data) : setCourses(data.data);
			});
	};

	const [working, setWorking] = useState(false);
	const assignCourse = (courseid) => {
		setOpencoursedialog(false);
		setWorking(true);
		var formData = new FormData();
		formData.append("assign-course", courseid);
		formData.append("studentid", studentid);
		fetch(apiglobal, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				setWorking(false);
				if (data.status === false) {
					snackbar(data.message);
				} else {
					snackbar(data.message);
					assignedCourses();
				}
			});
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [courseid, setCourseid] = useState("");
	const [ctitle, setCtitle] = useState("");
	const openmenu = (event, courseid, ctitle) => {
		setCtitle(ctitle);
		setCourseid(courseid);
		setAnchorEl(event.currentTarget);
	};

	const [issuedialog, setIssuedialog] = useState(false);
	const [enrollment, setEnrollment] = useState("");
	const [certificate, setCertificate] = useState("");
	const [obtained, setObtained] = useState("");
	const [registeration, setRegistration] = useState("");
	const [issuedate, setIssuedate] = useState("");
	const [issuestatus, setIssuestatus] = useState("");
	const getCertificateDetail = () => {
		setIssuedialog(true);
		var formData = new FormData();
		formData.append("get-certificate-detail", studentid);
		formData.append("courseid", courseid);
		fetch(apiadmin, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.status !== false) {
					setEnrollment(data.data.enrollment_no);
					setCertificate(data.data.certificate_no);
					setObtained(data.data.obtained);
					setRegistration(data.data.registration);
					setIssuedate(data.data.issued_on);
					setIssuestatus(data.data.status);
				}
			});
	};
	const issueCertificate = () => {
		setWorking(true);
		var formData = new FormData();
		formData.append("issue-certificate", studentid);
		formData.append("courseid", courseid);
		formData.append("enrollment", enrollment);
		formData.append("certificate", certificate);
		formData.append("obtained", obtained);
		formData.append("issuedate", issuedate);
		formData.append("registration", registeration);
		formData.append("status", issuestatus);
		fetch(apiadmin, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				setWorking(false);
				if (data.status === false) {
					snackbar(data.message);
				} else {
					setIssuedialog(false);
					snackbar(data.message);
				}
			});
	};

	const remove_course = () => {
		setAction("deleting");
		var formData = new FormData();
		formData.append("remove-assigned-course", studentid);
		formData.append("courseid", courseid);
		axios.post(apiadmin, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			res.data.status === true && assignedCourses();
			setOpendialog("");
		});
	};

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	const add_photo = (event) => {
		snackbar("Uploading...");
		setAction("uploadingphoto");
		var formData = new FormData();
		formData.append("add-student-photo", studentid);
		formData.append("image", event.target.files[0]);
		axios.post(apiglobal, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				window.location.reload();
			}
		});
	};

	const [documents, setDocuments] = useState([]);
	useEffect(() => {
		get_documents();
	}, []);
	const get_documents = async () => {
		try {
			const response = await axios.get(`${apiUrl}/global/v2?action=documents&studentId=${studentid}`);
			if (response.data.status === true) {
				setDocuments(response.data.data);
			} else {
				setDocuments(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			console.log("finally");
		}
	};

	const [docType, setDocType] = useState("");
	const upload_document = async (img) => {
		try {
			setAction("uploadingdocument");
			let formData = new FormData();
			formData.append("studentId", studentid);
			formData.append("documentType", docType);
			formData.append("img", img.target.files[0]);
			const response = await axios.post(`${apiUrl}/global/v2?action=upload-document`, formData);
			if (response.data.status === true) {
				snackbar(response.data.msg);
				get_documents();
			} else {
				snackbar(response.data.msg);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setAction("");
		}
	};

	const [docImg, setDocImg] = useState("");
	const [docId, setDocId] = useState("");

	const delete_document = async () => {
		try {
			setAction("deletingdocument");
			let formData = new FormData();
			formData.append("documentId", docId);
			formData.append("img", docImg);
			const response = await axios.post(`${apiUrl}/global/v2?action=delete-document`, formData);
			if (response.data.status === true) {
				snackbar(response.data.msg);
				get_documents();
			} else {
				snackbar(response.data.msg);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setOpendialog("");
			setAction("");
		}
	};

	return (
		<Box className="content">
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={working}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Dialog open={opendialog === "opendocument" ? true : false} onClose={() => setOpendialog("")}>
				<DialogContent>
					<img src={`../../spywareassets/student/document/${docImg}`} />
				</DialogContent>
			</Dialog>
			<Dialog open={opendialog === "delete" ? true : false} onClose={() => setOpendialog("")} fullWidth maxWidth="xs">
				<DialogContent>
					<Box textAlign={"center"}>
						<CircleNotifications sx={{ fontSize: 100, color: "error.main" }} />
						<Typography variant="h6" fontWeight={"bold"} color={"error"}>
							Do you really want to delete <br /> this document?
						</Typography>
						<Typography variant="body2" mt={1} color={"gray"}>
							You can't undo this action
						</Typography>

						<Stack mt={2} direction={"column"} spacing={1}>
							<Button onClick={() => delete_document()} disabled={action === "deletingdocument" ? true : false} variant="contained" color="error">
								Delete
							</Button>
							<Button variant="outlined" color="info" onClick={() => setOpendialog("")}>
								Cancel
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>

			<Container>
				<Back text="Student info" />
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Card sx={{ height: "100%" }}>
							<CardContent>
								<Box textAlign="center" mb={2}>
									<Badge
										overlap="circular"
										anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
										badgeContent={
											<IconButton component="label" disabled={action === "uploadingphoto" ? true : false} onChange={(event) => add_photo(event)}>
												<CameraAlt />
												<VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" />
											</IconButton>
										}
									>
										<Avatar alt={student.name} sx={{ width: 120, height: 120 }} src={`/spywareassets/student/${studentid}.jpg`} />
									</Badge>
									<Typography mt={1} fontSize={25} fontWeight="bold">
										{student.name}
									</Typography>
								</Box>
								<Stack direction={"row"} spacing={1} mb={2}>
									<Box>
										<Typography fontSize={10} color="gray">
											Join date
										</Typography>
										<Chip sx={{ borderRadius: 2 }} color="info" variant="outlined" icon={<CalendarMonth />} label={new Date(student.joindate).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "2-digit" })} />
									</Box>
									<Box>
										<Typography fontSize={10} color="gray">
											Status
										</Typography>
										<Chip sx={{ borderRadius: 2, textTransform: "capitalize" }} color={student.status === "active" ? "success" : "warning"} variant="outlined" icon={<Info />} label={student.status} />
									</Box>
								</Stack>
								<Divider sx={{ fontSize: 12, color: "gray" }}>DOCUMENTS</Divider>

								<Stack mt={1} direction={{ xs: "column", md: "row" }} spacing={1}>
									<FormControl fullWidth size="small">
										<InputLabel id="demo-simple-select-label">Document Type</InputLabel>
										<Select value={docType} onChange={(event) => setDocType(event.target.value)} labelId="demo-simple-select-label" id="demo-simple-select" label="Document Type">
											<MenuItem value={"aadhaar card"}>Aadhaar Card</MenuItem>
											<MenuItem value={"marksheet"}>Marksheet</MenuItem>
											<MenuItem value={"others"}>Others</MenuItem>
										</Select>
									</FormControl>
									<Button disabled={action === "uploadingdocument" ? true : false} fullWidth component="label" role={undefined} variant="outlined" color="info" startIcon={action === "uploadingdocument" ? <CircularProgress size={20} /> : <Image />}>
										Image
										<VisuallyHiddenInput type="file" onChange={(event) => upload_document(event)} accept=".jpeg, .jpg" />
									</Button>
								</Stack>
								{documents.status === false ? (
									<Alert severity="info" sx={{ mt: 2 }}>
										{documents.msg}
									</Alert>
								) : (
									documents.map((item, index) => {
										return (
											<Stack key={index} mt={2} direction={"row"} spacing={1} mb={2}>
												<Chip sx={{ borderRadius: 2, textTransform: "capitalize" }} color="info" variant="outlined" icon={<AttachFile />} label={item.document_type} />
												<Chip
													onClick={() => {
														setDocImg(item.document_file);
														setOpendialog("opendocument");
													}}
													sx={{ borderRadius: 2 }}
													clickable
													color="success"
													variant="outlined"
													icon={<Visibility />}
													label={"View"}
												/>
												<Chip
													onClick={() => {
														setOpendialog("delete");
														setDocImg(item.document_file);
														setDocId(item.documentid);
													}}
													sx={{ borderRadius: 2 }}
													clickable
													color="error"
													variant="outlined"
													icon={<Delete />}
													label={"Delete"}
												/>
											</Stack>
										);
									})
								)}
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card sx={{ height: "100%" }}>
							<CardContent>
								<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
									<Typography fontSize={14} color="gray">
										Assign course
									</Typography>
									<IconButton sx={{ bgcolor: "#F5F5F5" }} onClick={() => setOpencoursedialog(true)}>
										<Add />
									</IconButton>
								</Stack>
								<Divider />
								{acourse.status === false ? (
									<Alert severity="warning" sx={{ mt: 1 }}>
										{acourse.message}
									</Alert>
								) : (
									acourse.map((data, i) => (
										<Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} key={i}>
											<Box>
												<Typography fontSize={18} fontWeight="bold">
													{data.c_title}
												</Typography>
												<Typography fontSize={14} color="gray">
													{data.c_name}
												</Typography>
											</Box>
											<Stack direction="row">
												<IconButton sx={{ bgcolor: "#F5F5F5", ml: 0.5 }} onClick={(event) => openmenu(event, data.courseid, data.c_title)}>
													<ExpandMore />
												</IconButton>
											</Stack>
										</Stack>
									))
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={() => navi(`/idcard/${studentid}/${courseid}`)}>ID card</MenuItem>
				{ctitle !== "TYPING" && <MenuItem onClick={() => navi(`/controller/student-marks/${studentid}/${courseid}`)}>Marks</MenuItem>}
				{ctitle === "TYPING" ? <MenuItem onClick={() => navi(`/public/typing-certificate/${studentid}/${courseid}`)}>Certificate</MenuItem> : <MenuItem onClick={() => navi(`/public/computer-certificate/${studentid}/${courseid}`)}>Certificate</MenuItem>}
				{ctitle !== "TYPING" ? <MenuItem onClick={() => navi(`/public/computer-marksheet/${studentid}/${courseid}`)}>Marksheet</MenuItem> : null}
				{auth === "admin" ? <MenuItem onClick={() => getCertificateDetail()}>Issue</MenuItem> : null}
				{auth === "admin" ? <MenuItem onClick={() => setOpendialog("removedialog")}>Remove</MenuItem> : null}
			</Menu>
			<Dialog open={opencoursedialog} fullWidth maxWidth="xs" onClose={() => setOpencoursedialog(false)}>
				<DialogContent>
					{courses.status === false ? (
						<Alert severity="warning">{courses.message}</Alert>
					) : (
						courses.map((data, i) => (
							<Stack direction="row" alignItems="center" justifyContent="space-between" mt={1} key={i} sx={{ border: "thin solid #CCCCCC", borderRadius: 1.5, padding: 1, cursor: "pointer" }} onClick={() => assignCourse(data.courseid)}>
								<Box>
									<Typography fontSize={18} fontWeight="bold">
										{data.c_title}
									</Typography>
									<Typography fontSize={14} color="gray">
										Duration: {data.c_duration}
									</Typography>
									<Typography fontSize={14} color="gray">
										{data.c_name}
									</Typography>
								</Box>
							</Stack>
						))
					)}
				</DialogContent>
			</Dialog>

			<Dialog open={issuedialog} fullScreen={fullscreen} fullWidth maxWidth="md" onClose={() => setIssuedialog(false)}>
				<DialogTitle>Issue certificate</DialogTitle>
				<Divider />
				<DialogContent>
					<Typography fontSize={14} color={"blue"} mb={1}>
						Certificate detail
					</Typography>
					<Stack spacing={1} direction={{ md: "row", xs: "column" }}>
						<TextField fullWidth variant="outlined" label="Enrollment number" type="text" value={enrollment} onChange={(event) => setEnrollment(event.target.value)} />
						<TextField fullWidth variant="outlined" label="Certificate number" type="text" value={certificate} onChange={(event) => setCertificate(event.target.value)} />
						{ctitle !== "TYPING" && <TextField fullWidth variant="outlined" label={`Obtained marks(${ctitle}) (in %)`} type="number" value={obtained} onChange={(event) => setObtained(event.target.value)} />}
						{ctitle === "TYPING" && <TextField fullWidth variant="outlined" label="Typing speed" type="text" placeholder="Example: HIN - 35 & ENG - 40 W.P.M." value={obtained} onChange={(event) => setObtained(event.target.value)} />}
					</Stack>
					<Typography fontSize={14} color={"blue"} mb={1} mt={3}>
						Issue detail
					</Typography>
					<Stack spacing={1} direction={{ md: "row", xs: "column" }}>
						<TextField fullWidth variant="outlined" helperText="Registration date" type="date" value={registeration} onChange={(event) => setRegistration(event.target.value)} />
						<TextField fullWidth variant="outlined" helperText="Issue date" type="date" value={issuedate} onChange={(event) => setIssuedate(event.target.value)} />
					</Stack>
					<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
						<Typography color={"blue"}>Issue status</Typography>
						<Checkbox checked={issuestatus === "issued" ? true : false} onChange={() => setIssuestatus(issuestatus === "issued" ? "pending" : "issued")} />
					</Stack>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button variant="outlined" color="inherit" onClick={() => setIssuedialog(false)}>
						Close
					</Button>
					<Button variant="contained" color="primary" onClick={() => issueCertificate()} disabled={working}>
						{working ? <CircularProgress color="inherit" size={24} /> : "Issue"}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={opendialog === "removedialog" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpendialog("")}>
				<DialogContent>
					<Typography fontWeight={"bold"} fontSize={25} color={"darkorange"}>
						Do you want to delete this course?
					</Typography>
					<Typography fontSize={14} color={"gray"}>
						Remember, You can't undo this process.
					</Typography>

					<Typography fontSize={14} color={"gray"} mt={2}>
						To remove this course type '<i>delete</i>' below.
					</Typography>
					<TextField fullWidth variant="outlined" type="text" size="small" placeholder="type 'delete' here" value={action} onChange={(event) => setAction(event.target.value)} onKeyUp={(event) => event.key === "Enter" && action === "delete" && remove_course()} />
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button variant="outlined" onClick={() => setOpendialog("")}>
						Close
					</Button>
					<Button variant="contained" color="error" disabled={action === "delete" ? false : action === "deleting" ? true : true} onClick={() => remove_course()}>
						{action === "deleting" ? <CircularProgress color="inherit" size={24} /> : "Delete"}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
